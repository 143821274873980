import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Button from "components/_ui/Button";
import Layout from "components/Layout";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import MailchimpForm from "components/MailchimpForm";

const TalkHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        margin: 0 auto;
        width: 70%;
    }
`

const TalkHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const TalkTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const TalkDescription = styled("p")`
    text-align: center;
`

const TalkBody = styled("div")`
    max-width: 550px;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }

    h2, h3 {
        padding-top: 1em;
    }
`

const SpeakingLink = styled(OutboundLink)`
    margin-top: 3em;
    display: block;
    text-align: center;
    margin-bottom: 7em;
`

const Talk = ({ talk, meta }) => {
    return (
        <>
            <Helmet
                title={`${talk.talk_title[0].text} | Carolyn Stransky`}
                htmlAttributes={{ lang: "en" }}
                link={[
                    {
                        rel: `canonical`,
                        href: `https://workwithcarolyn.com/speaking/${talk._meta.uid}`
                    }
                ]}
                meta={[
                    {
                        name: `description`,
                        content: `${talk.talk_preview_description[0].text}`,
                    },
                    {
                        property: `og:title`,
                        content: `${talk.talk_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        property: `og:description`,
                        content: `${talk.talk_preview_description[0].text}`,
                    },
                    {
                        name: `og:image`,
                        content: `${talk.talk_preview_image.url}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: `${talk.talk_title[0].text} | Carolyn Stransky`,
                    },
                    {
                        name: `twitter:description`,
                        content: `${talk.talk_preview_description[0].text}`,
                    },
                    {
                        name: `twitter:image`,
                        content: `${talk.talk_preview_image.url}`,
                    }
                ].concat(meta)}
            />
            <Layout>

                <TalkTitle>
                    {RichText.render(talk.talk_title)}
                    <TalkDescription>
                        {RichText.render(talk.talk_preview_description)}
                    </TalkDescription>
                </TalkTitle>
                    {talk.talk_hero_image && (
                    <TalkHeroContainer>
                        <img src={talk.talk_hero_image.url} alt="" />
                        <TalkHeroAnnotation>
                            {RichText.render(talk.talk_hero_annotation)}
                        </TalkHeroAnnotation>
                    </TalkHeroContainer>
                )}
                <TalkBody>
                    {RichText.render(talk.talk_body)}
                    <SpeakingLink href="mailto:hello@workwithcarolyn.com?subject=Speaking+Inquiry" target="_blank" rel="noopener noreferrer">
                        <Button className="Button--secondary">
                            Book me to speak at your event
                        </Button>
                    </SpeakingLink>
                    <MailchimpForm
                        titleText="Liked this talk?"
                    />
                </TalkBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const talkContent = data.prismic.allTalks.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Talk talk={talkContent} meta={meta}/>
    )
}

Talk.propTypes = {
    talk: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query TalkQuery($uid: String) {
        prismic {
            allTalks(uid: $uid) {
                edges {
                    node {
                        talk_title
                        talk_hero_image
                        talk_hero_annotation
                        talk_preview_image
                        talk_date
                        talk_body
                        talk_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                baseOG
            }
        }
    }
`